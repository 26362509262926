import React from 'react';


const Item = ({ imageSrc, title, description }) => {
  return (
    <div style={styles.card}>
      <img src={imageSrc} alt={title} style={styles.image} />
      <div style={styles.textContainer}>
        <h3 style={styles.title}>{title}</h3>
        <p style={styles.description}>{description}</p>
      </div>
    </div>
  );
};

const styles = {
  card: {
    display: 'flex',
    flexDirection: 'row',
    width: '400px',
    height: '150px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: '8px',
    overflow: 'hidden',
    fontFamily: 'Arial, sans-serif',
  },
  image: {
    width: '150px',
    height: '100%',
    objectFit: 'cover',
  },
  textContainer: {
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flex: 1,
  },
  title: {
    margin: '0',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  description: {
    margin: '8px 0 0 0',
    fontSize: '14px',
    color: '#555',
  },
};

export default Item;