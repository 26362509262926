import React from 'react';

const AboutMePage = () => {
  return (
    <div>
      <h1>About Me</h1>
      <p>Here's where I share more about my journey, skills, and experiences.</p>
    </div>
  );
};

export default AboutMePage;