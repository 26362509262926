import React from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css'; 
import 'bootstrap/dist/js/bootstrap.bundle.min'; 
import { Row, Col } from 'react-bootstrap';
import content from '../english.json';

const Header = () => {
  const location = useLocation();
  const isHomepage = location.pathname === '/';

  // Define your projects here for the dropdown
  const projects = [
    { id: 'ysearch', name: 'Ysearch' },
    // { id: 'edtech', name: 'EdTech Books' },
    { id: 'chatbot', name: 'Topic Development Chatbot' },
    { id: 'math', name: 'Everybody Math' }
  ];

  console.log(content.grades)

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Row className="align-items-center w-100">
            <Col xs={1} md={1}></Col>
            <Col xs={5} md={2}>
              {/* Show back button on all pages except homepage */}
              {!isHomepage && (
                <NavLink to="/" className="me-3">
                  Back Home
                </NavLink>
              )}
            </Col>
            <Col xs={5} md={8}>
              <button 
                className="navbar-toggler" 
                type="button" 
                data-bs-toggle="collapse" 
                data-bs-target="#navbarNav" 
                aria-controls="navbarNav" 
                aria-expanded="false" 
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ms-auto">
                  {/* <li className="nav-item">
                    <NavLink 
                      exact
                      className="nav-link" 
                      to="/" 
                      style={({ isActive }) => ({
                        textDecoration: isActive ? 'underline' : 'none'
                      })}
                    >
                      Home
                    </NavLink>
                  </li> */}
                  <li className="nav-item dropdown">
                    <a 
                      className="nav-link dropdown-toggle" 
                      href="#" 
                      id="projectsDropdown" 
                      role="button" 
                      data-bs-toggle="dropdown" 
                      aria-expanded="false"
                    >
                      Elementary School
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="projectsDropdown">
                      {Object.values(content.grades).map((grade) => (
                        <li key={grade.id}>
                          <Link className="dropdown-item" to={`/project/${grade.id}`}>
                            {grade.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a 
                      className="nav-link dropdown-toggle" 
                      href="#" 
                      id="projectsDropdown" 
                      role="button" 
                      data-bs-toggle="dropdown" 
                      aria-expanded="false"
                    >
                      Middle School
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="projectsDropdown">
                      {Object.values(content.middle).map((grade) => (
                        <li key={grade.id}>
                          <Link className="dropdown-item" to={`/project/${grade.id}`}>
                            {grade.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a 
                      className="nav-link dropdown-toggle" 
                      href="#" 
                      id="projectsDropdown" 
                      role="button" 
                      data-bs-toggle="dropdown" 
                      aria-expanded="false"
                    >
                      High School
                    </a>
                    <ul className="dropdown-menu" aria-labelledby="projectsDropdown">
                      {Object.values(content.high).map((grade) => (
                        <li key={grade.id}>
                          <Link className="dropdown-item" to={`/project/${grade.id}`}>
                            {grade.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </div>
            </Col>
            <Col></Col>
          </Row>
        </div>
      </nav>
    </header>
  );
};

export default Header;
